import { TbLetterA, TbLetterB, TbLetterC } from "react-icons/tb";
import ActivitiesImg from "../../../assets/images/stock/4137320.jpg";
import React, { useState, useEffect } from "react";
import Typist from "react-typist";
import { useMobileWidth } from "../../hooks/useMobileWidth";
import CountdownTimer from "./countdown";

export function LandingPage() {
  const [count, setCount] = useState(1);
  const isMobileWidth = useMobileWidth();

  const typistOptions = [
    "Bowling",
    "Eat",
    "Golfing",
    "visit a Museum",
    "have a Blast",
  ];

  const steps = [
    "Search for activities in your area",
    "Browse available options",
    "Select your choice",
    "Confirm and enjoy!",
  ];

  useEffect(() => {
    setCount(1);
  }, [count]);

  const typistDelay = 800;

  return (
    <div style={{ ...styles.page, textAlign: "center" }}>
      <h1 style={styles.title}>Find & Sell Last Minute Reservations!</h1>

      <h1 style={styles.typistTitle}>
        Let's go{" "}
        <span style={styles.typistContainer}>
          {count ? (
            <Typist avgTypingDelay={50} onTypingDone={() => setCount(0)}>
              {typistOptions.map((option, idx) => (
                <span key={idx}>
                  {option + (idx === typistOptions.length - 1 ? "!" : "?")}
                  <Typist.Backspace count={20} delay={typistDelay} />
                </span>
              ))}
            </Typist>
          ) : (
            ""
          )}
        </span>
      </h1>

      <div style={isMobileWidth ? styles.mobileContainer : styles.container}>
        <div
          style={
            !isMobileWidth ? { ...styles.textWrapper, textAlign: "left" } : {}
          }
        >
          <div style={{ ...styles.textContent, textAlign: "center" }}>
            <h2 style={styles.subTitle}>How it Works</h2>
            <ul style={styles.list}>
              {steps.map((step, index) => (
                <li key={index} style={styles.listItem}>
                  {step}
                </li>
              ))}
            </ul>
            <h3 style={styles.guaranteeText}>
              Stay safe with our money-back guarantee and ticket settlement.
            </h3>
          </div>
        </div>

        {!isMobileWidth && (
          <img
            src={ActivitiesImg}
            alt="How it works illustration"
            style={styles.image}
          />
        )}
      </div>

      <h3 style={styles.title2}>Coming Soon</h3>
      <CountdownTimer targetDate="2025-01-01" />
    </div>
  );
}

const styles = {
  page: {
    padding: "0 20px",
  },
  title: {
    fontSize: "clamp(2rem, 5vw, 3rem)", // Responsive font size
    fontWeight: "bold",
    marginTop: "10vh",
    paddingBottom: "20px",
    color: "#333",
  },
  title2: {
    // fontFamily: "'Barriecito', cursive",
    fontSize: "clamp(2rem, 5vw, 3rem)", // Responsive font size
    fontWeight: "bold",
    paddingBottom: "20px",
    color: "#333",
  },
  typistTitle: {
    // fontFamily: "'Barriecito', cursive",
    fontSize: "clamp(2rem, 5vw, 3rem)", // Responsive font size
    paddingBottom: "20px",
    color: "#333",
    height: "10vh",
  },
  typistContainer: {
    display: "inline-flex",
    color: "#38A9FF",
  },
  container: {
    display: "flex",
    flexDirection: "row", // Ensure row direction on larger screens
    justifyContent: "space-between",
    alignItems: "flex-start", // Align items at the start
    padding: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  mobileContainer: {
    padding: "20px",
    display: "block",
  },
  textWrapper: {
    width: "100%", // Full width on mobile
    padding: "0 10px", // Padding for mobile
  },
  textContent: {
    padding: "20px",
  },
  subTitle: {
    fontSize: "clamp(1.5rem, 5vw, 2.2rem)", // Responsive font size
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#333",
  },
  list: {
    paddingLeft: "20px",
    paddingBottom: "30px",
  },
  listItem: {
    fontSize: "clamp(1rem, 4vw, 1.5rem)", // Responsive font size
    marginBottom: "8px",
    color: "#555",
  },
  guaranteeText: {
    fontSize: "clamp(1rem, 4vw, 1.8rem)", // Responsive font size
    marginTop: "20px",
    color: "#555",
  },
  image: {
    maxWidth: "50%", // Maintain maximum width for larger screens
    height: "auto",
    marginLeft: "20px",
    display: "block", // Block display for better spacing
    marginRight: "10px", // Add right margin for spacing
  },
};
