import { ISearchBy } from "../../interfaces/search";
import { IStayPreview } from "../../interfaces/reservation";

// Stay state:
export interface IStayState {
  reservations: IStayPreview[];
  isLoading: boolean;
  totalPages: number | null;
}

export type StayAction =
  | { type: "SET_STAYS"; reservations: IStayPreview[] }
  | { type: "LOAD_MORE_STAYS"; reservations: IStayPreview[] }
  | { type: "SET_IS_LOADING"; isLoading: boolean }
  | { type: "SET_FILTER"; searchBy: ISearchBy }
  | { type: "SET_TOTAL_PAGES"; totalPages: number };

const initialState: IStayState = {
  reservations: [],
  isLoading: false,
  totalPages: null,
};

export function reservationReducer(state = initialState, action: StayAction) {
  // {type: SOME_TYPE, data}

  switch (action.type) {
    // Stays
    case "SET_STAYS":
      return { ...state, reservations: action.reservations };
    case "LOAD_MORE_STAYS":
      return {
        ...state,
        reservations: [...state.reservations, ...action.reservations],
      };
    case "SET_TOTAL_PAGES":
      return { ...state, totalPages: action.totalPages };
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.isLoading };

    //   Defalut
    default:
      return { ...state };
  }
}
