import "./app.scss";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { AppHeader } from "./cmps/app-header";
import { Home } from "./pages/Home/home";
import { StayDetails } from "./pages/StayDetails/reservation-details";
import { AddReservationListing } from "./pages/AddReservationListing/reservation-edit";
import { useState } from "react";
import { AppFooter } from "./cmps/app-footer";
import { LandingPage } from "./pages/Landing/landing";

export interface UserProfile {
  picture: string;
  name: string;
  email: string;
}

function App() {
  const [profile, setProfile] = useState<UserProfile | null>(null);

  return (
    <div className="app">
      <AppHeader setProfile={setProfile} profile={profile} />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/" element={<Home />} />
          <Route path="/add-listing" element={<AddReservationListing />} />
          <Route path="/reservation/:reservationId" element={<StayDetails />} /> */}
        </Routes>
      </main>
      <AppFooter />
    </div>
  );
}

export default App;
