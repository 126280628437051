// Map setup and data
export const mapDefaults = {
  DEFAULT_MAP_PROPS: {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 2,
  },
  CURRENCY_SIGN: "$",
  MAP_STYLES: [
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "poi.medical",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text",
      stylers: [
        {
          color: "#bae3c5",
        },
      ],
    },
    {
      featureType: "poi.place_of_worship",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit.station.airport",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit.station.bus",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit.station.rail",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#b3e6f4",
        },
        {
          lightness: 5,
        },
        {
          visibility: "on",
        },
      ],
    },
  ],
};
