import { IFilter, IFilterBy } from "../interfaces/filter";
import { ISearchBy } from "../interfaces/search";
import { IReview } from "../interfaces/review";
import { IStay } from "../interfaces/reservation";
import { utilService } from "./util.service";
import { httpService } from "./http.service";
import { mapDefaults } from "../../constants/deault-map-setting";
import { FormData } from "../pages/AddReservationListing/create-reservation-form";

const API_KEY = "reservation";

export const reservationService = {
  loadStays,
  loadStayById,
  getDefaultSearch,
  loadFilters,
  getSearchFromParams,
  calcAvgRate,
  calcAvgRates,
  getDefaultFilter,
  createListing,
};

async function loadStays(
  searchBy: ISearchBy = getDefaultSearch(),
  filterBy: IFilterBy = getDefaultFilter(),
  pageIdx = 0
) {
  try {
    return await httpService.get(API_KEY, { pageIdx, filterBy, searchBy });
  } catch (err) {
    console.log("err:", err);
  }
}

async function loadStayById(reservationId: string) {
  try {
    return (await httpService.get(API_KEY + `/${reservationId}`)) as IStay;
  } catch (err) {
    console.log("err:", err);
  }
  // return storageService.get(STAY_DB_KEY, reservationId) as Promise<IStay>
}

function getSearchFromParams(paramsObj: { [k: string]: string }): ISearchBy {
  let searchObj: ISearchBy = {
    people: +paramsObj.guests || 1,
    destination: {
      label: paramsObj.label,
      lng: Number(paramsObj.lng),
      lat: Number(paramsObj.lat),
    },
    startDate: utilService.deformatDate(paramsObj.startDate) || null,
    endDate: utilService.deformatDate(paramsObj.endDate) || null,
  };
  return searchObj;
}

function getDefaultSearch(): ISearchBy {
  return {
    destination: { label: "Map View", ...mapDefaults.DEFAULT_MAP_PROPS.center },
    people: 1,
    startDate: null,
    endDate: null,
  };
}

function getDefaultFilter() {
  return {
    labels: [],
  };
}

async function loadFilters() {
  try {
    return (await httpService.get("filters")) as IFilter[];
  } catch (err) {
    console.log("err:", err);
  }
  // let filters = [...gFilters]
  // filters.forEach(filter => {
  //     filter._id = utilService.makeId()
  // })
  // return filters
}
export interface NewReservationData {
  reservationName: string;
  reservationType: string;
  reservationSummary: string;
  price: number;
  startDateTime: string;
  endDateTime: string | undefined;
  numOfPpl: number;
  userId: string;
  pictures: string[]; // Add pictures field
  location: {
    label: string;
    coords: { lat: number; lng: number };
  };
}
async function createListing(foo: NewReservationData) {
  try {
    return await httpService.post("create-listing", {
      ...foo,
      // imgUrls:
      //   foo.imgUrls.length > 0
      //     ? foo.imgUrls
      //     : [
      //         "http://res.cloudinary.com/dmtlr2viw/image/upload/v1663436922/ytr7znhbuqnxnotutplv.jpg",
      //       ],
    });
  } catch (err) {
    console.log("err:", err);
  }
  // let filters = [...gFilters]
  // filters.forEach(filter => {
  //     filter._id = utilService.makeId()
  // })
  // return filters
}

function calcAvgRates(reviews: IReview[]): { [key: string]: number } {
  const avgRates: { [key: string]: number } = {};
  const numReviews = reviews.length;

  reviews.forEach((review) => {
    const moreRates = review.moreRate;

    Object.entries(moreRates).forEach(([key, value]) => {
      avgRates[key] = avgRates[key] || 0;
      avgRates[key] += value / numReviews;
    });
  });

  Object.entries(avgRates).forEach(([key, value]) => {
    avgRates[key] = parseFloat(value.toFixed(1));
  });

  return avgRates;
}

function getRatesSum(reservationReviews: IReview[]) {
  return reservationReviews.reduce((acc, review) => {
    const rates = Object.values(review.moreRate);
    let ratesSum = 0;
    rates.forEach((r) => (ratesSum += r));
    acc += ratesSum / rates.length;
    return acc;
  }, 0);
}
function calcAvgRate(reservationReviews: IReview[]): string {
  if (!reservationReviews.length) return "0";
  let allRatesSum = getRatesSum(reservationReviews);
  return (allRatesSum / reservationReviews.length).toFixed(2);
}
