import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { useRef, useState, useEffect, ReactNode } from "react";
import { ISearchBy, ISearchByOpts } from "../interfaces/search";
import { reservationService } from "../services/reservation.service";
import { AppLogo } from "./app-logo";
import { OverlayScreen } from "./overlay-screen";
import { SearchForm } from "./search-form";
import { SearchTeaser } from "./search-teaser";
import { UserMenu } from "./user-menu";
import { utilService } from "../services/util.service";
import { useMobileWidth } from "../hooks/useMobileWidth";
import { MobileSearchTeaser } from "./mobile-search-teaser";
import Modal from "./modal";
// import { MobileSearchForm } from "./mobile-search-form";
import { UserProfile } from "../app";
// import {
//   googleLogout,
//   useGoogleLogin,
//   TokenResponse,
// } from "@react-oauth/google";
import axios from "axios";

export interface ISearchProps {
  activeModule: string | null;
  onChangeModule: (module: string | null) => void;
  searchBy: ISearchBy;
  isExpandedClass: string;
  onChangeIsExpanded: (value: boolean) => void;
  onSetSearchBy: (searchBy: ISearchByOpts) => void;
  handleFormSubmit: () => void;
}

export interface IAppHeaderProps {
  profile: UserProfile | null;
  setProfile: React.Dispatch<React.SetStateAction<UserProfile | null>>;
}

// Define the type for the Dialog props
interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  children,
}) => {
  if (!isOpen) return null; // Render nothing if the dialog is closed

  return (
    <div className="dialog-overlay" onClick={onClose}>
      <div className="dialog-content" onClick={(e) => e.stopPropagation()}>
        <h2>{title}</h2>
        <div>{children}</div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export function AppHeader(props: IAppHeaderProps) {
  // State
  const [activeModule, setActiveModule] = useState<string | null>(null);
  const [searchBy, setSearchBy] = useState<ISearchBy>(
    reservationService.getDefaultSearch()
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isDetailsPage, setIsDetailsPage] = useState<boolean>(false);
  const isMobileWidth = useMobileWidth();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [user, setUser] = useState<Omit<
  //   TokenResponse,
  //   "error" | "error_description" | "error_uri"
  // > | null>(null);

  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => setIsDialogOpen(false);

  // Refs
  const searchFormContainerRef = useRef<HTMLDivElement>(null);
  // Search Params
  let [_, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setIsDetailsPage(location.pathname.includes("/reservation/"));
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.toString() === "") return;
    let paramsObj = Object.fromEntries(searchParams.entries());
    let searchObj = reservationService.getSearchFromParams(paramsObj);
    setSearchBy(searchObj);
  }, [location]);

  function handleFormSubmit() {
    const { destination, startDate, endDate, people } = searchBy;
    const oneDay = 1000 * 60 * 60 * 24;
    if (!endDate && startDate) {
      searchBy.endDate = new Date(startDate.getTime() + oneDay);
    } else if (!startDate && endDate) {
      searchBy.startDate = new Date(endDate.getTime() - oneDay);
    }
    onChangeIsExpanded(false);
    const params = new URLSearchParams({
      ...(destination.label && { label: destination.label }),
      lng: String(destination.lng),
      lat: String(destination.lat),
      startDate: utilService.formatDate(startDate || new Date()),
      endDate: utilService.formatDate(endDate || new Date(Date.now() + oneDay)),
      guests: people.toString(),
    });
    if (isDetailsPage) {
      navigate(`/airbnb-frontend/?${params}`);
    } else {
      setSearchParams(params);
    }
  }

  function onSetSearchBy(
    searchByOpts: Pick<
      ISearchByOpts,
      "destination" | "people" | "startDate" | "endDate"
    >
  ) {
    setSearchBy((prev) => ({ ...prev, ...searchByOpts }));
  }

  // Methods
  function onChangeModule(module: string | null) {
    if (activeModule === module) return;
    setActiveModule(module);
  }

  function onChangeIsExpanded(value: boolean) {
    setIsExpanded(value);
    if (!value) setActiveModule(null);
  }

  function onBackHome() {
    navigate("/");
    setSearchBy(reservationService.getDefaultSearch());
    setActiveModule(null);
    setIsExpanded(false);
  }

  const handleFormBlur = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (!isExpanded) return;
    const container = searchFormContainerRef.current;
    const target = ev.target as Node;
    if (container && !container.contains(target)) {
      setActiveModule(null);
    }
  };

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => setUser(codeResponse),
  //   onError: (error) => console.log("Login Failed:", error),
  // });

  const handleLoginClick = () => {
    // login();
    closeDialog();
  };

  const handleLogOutClick = () => {
    // googleLogout();
    props.setProfile(null);
    closeDialog();
  };

  useEffect(
    () => {
      // if (user) {
      //   axios
      //     .get(
      //       `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
      //       {
      //         headers: {
      //           Authorization: `Bearer ${user.access_token}`,
      //           Accept: "application/json",
      //         },
      //       }
      //     )
      //     .then((res) => {
      //       props.setProfile(res.data);
      //     })
      //     .catch((err) => console.log(err));
      // }
    },
    [
      // user
    ]
  );

  // Props
  const isExpandedClass: string = isExpanded ? "expanded" : "";
  const searchProps = {
    activeModule,
    onChangeModule,
    searchBy,
    isExpandedClass,
    onChangeIsExpanded,
    onSetSearchBy,
    handleFormSubmit,
  };
  // Template
  return !isMobileWidth ? (
    <div onClick={handleFormBlur} className={`app-header`}>
      <OverlayScreen
        isOpen={isExpanded}
        setIsOpen={() => setIsExpanded(false)}
      />
      <header
        className={`${isExpandedClass} ${
          isDetailsPage ? "secondary-layout" : "main-layout"
        } `}
      >
        <div className="wrapper flex align-center justify-center">
          <div
            onClick={() => onBackHome()}
            // className="logo"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppLogo />
          </div>
          {/* <SearchTeaser {...searchProps} />
  <UserMenu
    profile={props.profile}
    openLoginDialog={openDialog}
    closeLoginDialog={closeDialog}
  /> */}
        </div>

        <div
          ref={searchFormContainerRef}
          className={`secondary-layout search-form-container ${isExpandedClass} ${
            !activeModule ? "blured" : ""
          }`}
        >
          {/* <SearchForm {...searchProps} /> */}
        </div>
        {isDialogOpen ? (
          <Dialog
            isOpen={isDialogOpen}
            onClose={closeDialog}
            title="Custom Dialog"
          >
            {!props.profile ? (
              <button onClick={handleLoginClick}>Sign in with Google 🚀</button>
            ) : (
              <button onClick={handleLogOutClick}>Sign Out</button>
            )}
          </Dialog>
        ) : (
          <></>
        )}
      </header>
    </div>
  ) : (
    <div className="mobile-header main-layout">
      <div
        onClick={() => onBackHome()}
        // className="logo"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AppLogo />
      </div>
      {/* <MobileSearchTeaser {...searchProps} /> */}
      {/* {isExpanded && (
        <Modal isOpen={isExpanded} onClose={() => setIsExpanded(false)}>
          <MobileSearchForm {...searchProps} />
        </Modal>
      )} */}
    </div>
  );
}
