export function AppLogo() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      // height="50"
      height="80"
      viewBox="0 0 2203 654"
      enableBackground="new 0 0 2203 654"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M1403.000000,655.000000 
	C935.333313,655.000000 468.166656,655.000000 1.000000,655.000000 
	C1.000000,437.000000 1.000000,219.000000 1.000000,1.000000 
	C735.333313,1.000000 1469.666626,1.000000 2204.000000,1.000000 
	C2204.000000,219.000000 2204.000000,437.000000 2204.000000,655.000000 
	C1937.166626,655.000000 1670.333374,655.000000 1403.000000,655.000000 
M669.007629,263.982147 
	C669.018982,272.493958 670.582336,274.146027 679.211487,273.990112 
	C692.483215,273.750336 705.751770,273.331512 719.064148,273.898590 
	C719.377014,278.332672 720.001709,282.770294 719.951782,287.200287 
	C719.644470,314.469147 719.267578,341.737915 718.735962,369.003204 
	C718.518860,380.136505 717.779480,391.258606 717.416260,402.390320 
	C716.922852,417.513306 716.464539,432.638855 716.190430,447.766907 
	C716.071533,454.324921 717.054749,455.045837 723.802673,455.119446 
	C726.461243,455.148438 729.119080,455.233032 731.862366,456.139404 
	C731.893188,457.118225 731.933960,458.096893 731.953186,459.075928 
	C732.076477,465.346741 732.716736,465.959991 739.145264,466.018799 
	C741.756531,466.042694 744.367615,466.100342 747.012939,466.994690 
	C747.131958,476.375824 747.744446,476.997772 756.872681,476.998627 
	C765.038269,476.999359 773.203918,477.018280 781.369446,476.992157 
	C787.195862,476.973511 788.074707,476.185303 787.971741,470.309692 
	C787.738770,457.010773 787.059692,443.713135 787.107178,430.416931 
	C787.290771,378.957886 787.684692,327.499573 788.032776,276.041199 
	C788.039001,275.116150 788.448730,274.193848 788.735107,273.000000 
	C790.914429,273.000000 792.900391,273.000092 794.886414,273.000031 
	C817.050110,272.999054 839.213928,273.025269 861.377563,272.982910 
	C869.619141,272.967163 874.396179,269.352966 874.512451,262.524994 
	C874.636597,255.228317 873.929871,247.921005 873.916565,240.617722 
	C873.893066,227.693970 873.728943,214.747223 874.471863,201.858994 
	C874.705444,197.806732 874.227173,194.500336 871.107422,192.813217 
	C867.480408,190.851791 863.144226,190.201843 859.026917,188.212082 
	C858.681274,182.984039 855.677368,180.100769 850.767029,179.010132 
	C848.347534,178.472733 845.884155,178.133316 843.349548,176.947021 
	C841.979126,170.128937 837.579590,167.324265 828.764771,167.343460 
	C811.627380,167.380798 794.489380,167.229416 777.352173,167.300339 
	C733.394470,167.482239 689.437134,167.743042 645.479614,167.972717 
	C645.313110,167.973587 645.146240,167.991165 644.980225,167.984039 
	C640.712280,167.800735 638.950867,169.995850 639.032043,174.098724 
	C639.117493,178.419113 639.088257,182.741791 638.241882,187.022537 
	C636.235046,187.002899 634.228271,186.983261 632.101562,186.081696 
	C632.145447,176.124619 632.145447,176.123749 622.340393,176.050201 
	C620.518921,176.036545 618.697266,176.032928 616.793945,175.146652 
	C617.227051,165.951157 616.711426,165.383209 607.735779,165.188675 
	C605.905212,165.149002 604.074463,165.117264 602.243713,165.087036 
	C593.032654,164.934921 590.908875,167.042450 591.068542,176.507797 
	C591.233826,186.313477 591.952759,196.128159 591.691833,205.919235 
	C590.964844,233.196487 589.483398,260.456635 588.975403,287.736115 
	C587.949829,342.814789 587.388916,397.902008 586.567383,452.984680 
	C586.513916,456.568146 587.713501,458.246826 591.446655,458.073761 
	C594.933350,457.912109 598.434570,458.065002 602.032471,458.969940 
	C602.037720,460.116577 602.049072,461.263184 602.047302,462.409821 
	C602.037048,468.874786 602.128845,468.955292 608.649353,468.998932 
	C611.759399,469.019714 614.868958,469.096558 617.977539,470.038147 
	C618.014893,480.849854 616.757263,480.043182 627.828430,479.942261 
	C631.118103,479.912292 634.462463,479.657410 637.677368,478.997192 
	C643.477478,477.806183 645.560059,474.356323 644.924133,468.513611 
	C644.155334,461.449707 643.394226,454.367432 643.097229,447.273529 
	C642.562805,434.507233 642.069214,421.725311 642.109924,408.951691 
	C642.190002,383.866241 642.588440,358.781067 642.971558,333.697571 
	C643.212402,317.927246 643.615417,302.158752 644.039001,286.391998 
	C644.341858,275.115601 644.799683,263.843353 646.026794,252.382217 
	C648.611938,252.347610 651.197021,252.313004 653.764343,253.003128 
	C652.141541,259.240570 654.789917,262.892853 661.102234,263.065094 
	C663.725891,263.136719 666.351990,263.118500 669.007629,263.982147 
M992.991638,264.034698 
	C992.966980,272.766937 994.250977,274.144379 1003.111511,273.989655 
	C1016.399475,273.757690 1029.684326,273.348480 1043.175049,273.851746 
	C1043.397705,277.169678 1043.835938,280.489380 1043.808228,283.805206 
	C1043.631104,305.094177 1043.586792,326.388214 1043.047241,347.669159 
	C1042.351685,375.101959 1041.205933,402.523224 1040.315674,429.951416 
	C1040.105103,436.434967 1040.154541,442.926697 1040.057373,449.414246 
	C1039.999023,453.311798 1041.712646,455.257355 1045.778076,455.138885 
	C1049.095337,455.042267 1052.421997,455.264282 1055.814941,456.185516 
	C1055.844238,457.165070 1055.881836,458.144409 1055.901367,459.124176 
	C1056.023438,465.258514 1056.690918,465.933868 1062.645508,466.018188 
	C1065.420288,466.057465 1068.194458,466.134705 1070.994141,467.051361 
	C1071.076904,476.488251 1071.577637,476.997711 1080.781494,476.998566 
	C1089.102661,476.999359 1097.427002,476.864685 1105.743896,477.052612 
	C1110.201050,477.153320 1112.271729,475.468170 1111.995728,470.916260 
	C1111.604614,464.465179 1111.093506,458.005981 1111.084351,451.549622 
	C1111.010132,399.292389 1110.990601,347.034943 1111.100098,294.777832 
	C1111.114868,287.709259 1111.939087,280.642395 1112.424927,273.000000 
	C1114.427246,273.000000 1116.209717,273.000000 1117.992310,273.000000 
	C1135.300537,273.000000 1152.609253,273.086792 1169.916626,272.958801 
	C1177.199829,272.904938 1184.479736,272.397461 1191.829224,272.927521 
	C1187.007812,318.391724 1191.070435,362.859375 1205.912720,406.307007 
	C1213.552124,428.669952 1225.830933,446.790497 1247.923096,457.923401 
	C1253.307251,461.590210 1258.691528,465.256989 1264.278687,469.575256 
	C1268.574219,472.353149 1272.535278,476.137207 1277.234985,477.698730 
	C1286.914795,480.915009 1296.872314,483.665344 1306.928223,485.295074 
	C1322.602661,487.835449 1337.760010,485.355743 1351.506104,476.942688 
	C1367.982300,466.858856 1378.499878,451.493530 1386.968384,434.766907 
	C1401.374268,406.313507 1407.453491,375.615265 1408.842285,344.034332 
	C1409.849731,321.129211 1407.916138,298.293976 1405.126221,275.505798 
	C1402.109009,250.861282 1394.494995,228.157990 1378.192749,209.188629 
	C1373.280518,203.472702 1366.221680,199.601578 1359.589111,194.520325 
	C1354.698853,191.064331 1349.808594,187.608322 1344.425171,183.695633 
	C1333.854614,173.817734 1321.585205,167.228912 1307.334595,164.402405 
	C1273.759766,157.743042 1244.953735,165.964996 1222.431274,192.366226 
	C1210.618896,206.212845 1203.363281,222.667664 1197.341064,239.328812 
	C1197.234131,237.681107 1196.998291,236.030090 1197.040771,234.386261 
	C1197.340576,222.786865 1197.586670,211.184158 1198.087524,199.592773 
	C1198.307251,194.504913 1195.432861,191.853973 1191.329712,190.355469 
	C1188.748535,189.412704 1185.822266,189.414566 1182.975708,188.223312 
	C1182.788940,183.523468 1180.107544,180.612442 1175.896362,179.280991 
	C1173.136230,178.408264 1170.143188,178.271622 1167.184814,177.047150 
	C1165.854004,170.167084 1161.477417,167.330688 1152.699585,167.344025 
	C1135.060181,167.370834 1117.420166,167.196396 1099.781128,167.271118 
	C1056.317871,167.455215 1012.855164,167.762634 969.391907,167.930939 
	C964.729187,167.949005 962.932068,169.800842 962.887207,174.513443 
	C962.663269,197.985870 962.226929,221.456451 961.811646,244.926727 
	C961.737244,249.127136 963.628235,251.667648 967.756409,252.102188 
	C971.047974,252.448685 974.391785,252.298584 977.397583,253.272583 
	C976.670776,260.619690 978.773132,263.009430 986.012268,263.080994 
	C988.332092,263.103912 990.651611,263.150055 992.991638,264.034698 
M1830.057739,468.809326 
	C1831.987549,470.344452 1833.796509,472.069458 1835.865967,473.384827 
	C1852.518555,483.969849 1871.097046,486.812897 1890.289429,485.829987 
	C1903.860962,485.134949 1916.126587,480.131042 1926.450684,471.031342 
	C1944.979614,454.699615 1956.370605,433.732605 1963.730957,410.677185 
	C1973.485474,380.121826 1978.031738,348.746979 1975.055908,316.554596 
	C1973.654663,301.396210 1972.561890,286.207794 1971.022827,271.064087 
	C1968.604614,247.270447 1959.613647,226.072876 1944.050903,207.998444 
	C1939.797241,203.058319 1933.502441,199.875610 1927.538940,195.596939 
	C1922.433960,191.961105 1917.328979,188.325272 1911.702271,184.302780 
	C1901.053589,173.911819 1888.460815,167.293854 1873.843506,164.347031 
	C1843.057129,158.140579 1815.769775,164.794891 1793.602295,187.444321 
	C1778.511475,202.863251 1769.102051,221.997742 1764.196167,242.858459 
	C1751.794312,295.593262 1754.727905,347.817352 1770.483398,399.573364 
	C1778.155762,424.776794 1790.449097,445.988495 1815.309692,458.523193 
	C1820.171143,461.733887 1825.032471,464.944580 1830.057739,468.809326 
M2054.284668,469.565338 
	C2054.992432,470.260071 2055.607178,471.097015 2056.421143,471.628876 
	C2076.904785,485.008240 2099.402832,488.893829 2123.106201,484.282135 
	C2142.126221,480.581573 2155.368896,468.178680 2166.190186,452.706390 
	C2188.713867,420.502289 2196.502930,383.723145 2198.781006,345.537659 
	C2200.175781,322.167084 2198.046387,298.803192 2195.155273,275.510193 
	C2192.095459,250.857529 2184.593750,228.132736 2168.212891,209.192688 
	C2163.277588,203.486465 2156.241211,199.597168 2149.609131,194.510223 
	C2144.717041,191.047470 2139.825195,187.584717 2134.440430,183.667267 
	C2111.007324,162.559799 2083.491943,157.807968 2053.900879,165.361542 
	C2027.303955,172.150879 2010.109009,190.674759 1998.083252,214.635345 
	C1986.667725,237.380096 1982.358032,261.725830 1980.702148,286.832458 
	C1978.008423,327.671112 1982.795166,367.442657 1995.959595,406.270325 
	C2003.540283,428.628906 2015.844971,446.769165 2037.935547,457.908569 
	C2043.320068,461.576385 2048.704590,465.244171 2054.284668,469.565338 
M1561.926758,466.055359 
	C1561.926758,466.055359 1561.914917,466.124176 1561.956543,466.921997 
	C1562.621948,474.838684 1564.853149,477.091400 1572.439941,476.976440 
	C1589.232300,476.722015 1606.024170,476.398102 1622.813477,475.989868 
	C1628.277588,475.856995 1633.761597,475.625397 1639.187866,475.009460 
	C1666.133545,471.950653 1691.715210,465.204498 1712.939331,447.023407 
	C1724.717651,436.933685 1735.467163,426.115936 1742.673950,411.992645 
	C1752.459106,392.816254 1755.516479,372.170197 1755.670898,351.242065 
	C1755.792725,334.747803 1754.140869,318.121155 1751.847900,301.753510 
	C1747.232666,268.809448 1731.825439,241.170471 1707.707275,218.429993 
	C1704.070679,215.001175 1699.369751,212.701065 1694.561523,209.572281 
	C1689.684570,206.085083 1684.807617,202.597885 1679.520142,198.589828 
	C1677.272827,196.870071 1675.032959,195.140472 1672.776978,193.432144 
	C1650.846069,176.824677 1626.130493,168.142395 1598.618164,168.044754 
	C1585.975830,167.999878 1573.331055,168.835327 1560.686646,169.252701 
	C1553.054443,169.504639 1545.404419,169.485916 1537.793335,170.024979 
	C1531.456665,170.473785 1529.989014,172.320129 1530.089966,178.562866 
	C1530.186646,184.551605 1530.574707,190.535492 1530.682251,196.524246 
	C1531.355225,233.992508 1529.321045,271.404053 1528.545654,308.835266 
	C1527.608643,354.058807 1529.843262,399.350616 1530.851196,444.609619 
	C1531.038330,453.003326 1533.565674,455.090088 1541.848511,455.113190 
	C1543.179810,455.116882 1544.510864,455.168884 1545.974365,455.926941 
	C1548.669312,465.621490 1549.151123,465.993408 1559.037720,466.011871 
	C1560.020630,466.013702 1561.003662,466.016724 1561.926758,466.055359 
M345.531860,45.945843 
	C343.648956,39.575508 341.477692,33.273872 339.940491,26.821194 
	C336.219391,11.201426 320.628326,2.688835 304.600708,7.630334 
	C280.287781,15.126300 255.833328,22.162016 231.471878,29.502401 
	C214.457352,34.629074 207.433456,47.202328 211.702103,64.509842 
	C216.892380,85.554161 204.958664,106.494606 184.180725,112.802452 
	C163.828354,118.981102 141.254868,107.898560 135.137268,87.718666 
	C131.003967,74.084274 116.908859,63.355255 98.855934,69.000122 
	C74.732216,76.543213 50.563969,83.992554 26.198063,90.693962 
	C11.886840,94.630020 1.641069,109.505272 6.822912,126.457420 
	C28.132635,196.171158 48.798225,266.081970 69.653702,335.934357 
	C99.154945,434.744476 128.618698,533.565796 158.087418,632.385620 
	C162.093079,645.818054 175.576187,654.098755 189.437408,650.243347 
	C216.186859,642.803345 242.824905,634.917297 269.298737,626.550476 
	C283.233246,622.146484 289.613220,608.316101 286.225861,594.064880 
	C285.019409,588.989258 284.468445,583.497986 284.962341,578.324707 
	C286.736511,559.741882 301.874542,544.886780 320.194946,543.045471 
	C339.281616,541.127197 356.768799,552.242371 362.690796,570.056641 
	C367.990448,585.998779 381.481049,593.174683 397.697906,588.399231 
	C422.264801,581.164856 446.807861,573.846130 471.304657,566.378296 
	C488.470001,561.145325 495.413574,547.890259 490.294952,530.739197 
	C464.698364,444.972717 439.097076,359.207611 413.499054,273.441559 
	C390.940857,197.860306 368.384766,122.278419 345.531860,45.945843 
z"
      />
      <path
        fill="#329FFF"
        opacity="1.000000"
        stroke="none"
        d="
M345.679810,46.321327 
	C368.384766,122.278419 390.940857,197.860306 413.499054,273.441559 
	C439.097076,359.207611 464.698364,444.972717 490.294952,530.739197 
	C495.413574,547.890259 488.470001,561.145325 471.304657,566.378296 
	C446.807861,573.846130 422.264801,581.164856 397.697906,588.399231 
	C381.481049,593.174683 367.990448,585.998779 362.690796,570.056641 
	C356.768799,552.242371 339.281616,541.127197 320.194946,543.045471 
	C301.874542,544.886780 286.736511,559.741882 284.962341,578.324707 
	C284.468445,583.497986 285.019409,588.989258 286.225861,594.064880 
	C289.613220,608.316101 283.233246,622.146484 269.298737,626.550476 
	C242.824905,634.917297 216.186859,642.803345 189.437408,650.243347 
	C175.576187,654.098755 162.093079,645.818054 158.087418,632.385620 
	C128.618698,533.565796 99.154945,434.744476 69.653702,335.934357 
	C48.798225,266.081970 28.132635,196.171158 6.822912,126.457420 
	C1.641069,109.505272 11.886840,94.630020 26.198063,90.693962 
	C50.563969,83.992554 74.732216,76.543213 98.855934,69.000122 
	C116.908859,63.355255 131.003967,74.084274 135.137268,87.718666 
	C141.254868,107.898560 163.828354,118.981102 184.180725,112.802452 
	C204.958664,106.494606 216.892380,85.554161 211.702103,64.509842 
	C207.433456,47.202328 214.457352,34.629074 231.471878,29.502401 
	C255.833328,22.162016 280.287781,15.126300 304.600708,7.630334 
	C320.628326,2.688835 336.219391,11.201426 339.940491,26.821194 
	C341.477692,33.273872 343.648956,39.575508 345.679810,46.321327 
M108.850929,142.659546 
	C104.563309,137.792297 100.333923,132.871323 95.936699,128.105179 
	C95.102509,127.200996 93.399338,126.182487 92.442123,126.450226 
	C83.378014,128.985474 74.388580,131.787704 65.120934,134.590698 
	C98.032654,244.774796 130.802063,354.482483 163.680267,464.554352 
	C172.531570,462.011841 180.764221,459.647064 189.373444,457.174103 
	C194.501663,474.340729 199.435410,490.856354 204.478760,507.738892 
	C195.694229,510.413849 187.467026,512.919128 178.896774,515.528809 
	C186.503128,540.967163 194.003830,566.052124 201.649719,591.622681 
	C211.727005,588.619751 221.240753,585.784729 230.381424,583.060913 
	C231.279434,576.519409 231.772415,570.366028 233.003662,564.364136 
	C242.948227,515.887756 289.574829,483.461182 338.568787,490.838257 
	C364.194458,494.696777 384.696991,507.135803 400.421051,527.682556 
	C401.529358,529.130798 403.936066,531.002930 405.220337,530.670959 
	C414.196411,528.351135 423.035828,525.502319 432.300018,522.707886 
	C424.671265,497.173828 417.171326,472.070801 409.564392,446.609772 
	C396.557373,450.463593 384.024506,454.176910 371.101898,458.005737 
	C365.998291,440.872528 361.036835,424.216370 356.001099,407.310913 
	C369.091705,403.353546 381.600311,399.572144 394.377106,395.709686 
	C361.409637,285.339569 328.635315,175.615997 295.923004,66.100060 
	C294.899048,66.100060 294.544434,66.022125 294.238831,66.111603 
	C288.333740,67.840263 282.425476,69.559082 276.535889,71.339386 
	C266.828522,74.273712 266.850769,74.293129 265.930054,84.223274 
	C265.776886,85.875755 265.440796,87.514854 265.128113,89.148438 
	C258.175476,125.468010 237.341003,150.574173 202.472015,162.308838 
	C168.278351,173.816238 136.823822,166.930618 108.850929,142.659546 
z"
      />
      <path
        fill="#319FFF"
        opacity="1.000000"
        stroke="none"
        d="
M1545.842041,455.198547 
	C1544.510864,455.168884 1543.179810,455.116882 1541.848511,455.113190 
	C1533.565674,455.090088 1531.038330,453.003326 1530.851196,444.609619 
	C1529.843262,399.350616 1527.608643,354.058807 1528.545654,308.835266 
	C1529.321045,271.404053 1531.355225,233.992508 1530.682251,196.524246 
	C1530.574707,190.535492 1530.186646,184.551605 1530.089966,178.562866 
	C1529.989014,172.320129 1531.456665,170.473785 1537.793335,170.024979 
	C1545.404419,169.485916 1553.054443,169.504639 1560.686646,169.252701 
	C1573.331055,168.835327 1585.975830,167.999878 1598.618164,168.044754 
	C1626.130493,168.142395 1650.846069,176.824677 1672.776978,193.432144 
	C1675.032959,195.140472 1677.272827,196.870071 1679.764893,199.186615 
	C1681.065430,201.051270 1682.041626,202.396805 1683.189087,203.575317 
	C1696.484375,217.227631 1706.441040,232.977982 1713.120728,250.826492 
	C1718.860474,266.163391 1721.627197,282.052826 1723.264160,298.322144 
	C1725.118530,316.752960 1725.385986,335.154358 1722.745605,353.387695 
	C1718.951050,379.591522 1708.037598,401.913788 1687.789429,420.271973 
	C1663.499390,442.294861 1634.594482,450.773041 1603.357056,453.830902 
	C1589.656982,455.172028 1575.778564,454.692413 1561.516602,455.006744 
	C1555.982056,455.048798 1550.912109,455.123688 1545.842041,455.198547 
M1634.837280,278.452332 
	C1634.562012,278.198456 1634.286743,277.944611 1633.958496,277.026062 
	C1630.684937,273.619446 1627.411499,270.212860 1623.989258,266.138428 
	C1618.232178,260.547607 1611.460083,256.951660 1604.140503,255.141953 
	C1603.035278,292.677155 1601.947266,329.633362 1600.859131,366.589600 
	C1601.450439,366.856201 1602.041748,367.122803 1602.633057,367.389435 
	C1607.423950,363.672272 1612.214844,359.955109 1617.680786,356.049042 
	C1623.068848,349.660004 1628.457031,343.270996 1634.085083,336.220703 
	C1644.242554,317.302216 1645.609863,298.243073 1635.000000,279.000000 
	C1635.000000,279.000000 1635.005493,279.000336 1634.837280,278.452332 
z"
      />
      <path
        fill="#319FFF"
        opacity="1.000000"
        stroke="none"
        d="
M1815.225220,457.845215 
	C1790.449097,445.988495 1778.155762,424.776794 1770.483398,399.573364 
	C1754.727905,347.817352 1751.794312,295.593262 1764.196167,242.858459 
	C1769.102051,221.997742 1778.511475,202.863251 1793.602295,187.444321 
	C1815.769775,164.794891 1843.057129,158.140579 1873.843506,164.347031 
	C1888.460815,167.293854 1901.053589,173.911819 1911.989990,184.850052 
	C1925.213867,200.226456 1933.648682,217.365860 1937.863037,236.504684 
	C1943.161621,260.567261 1944.214233,285.119415 1944.726685,309.611450 
	C1944.988647,322.130676 1943.488892,334.754364 1941.957642,347.229279 
	C1938.860229,372.464966 1931.996948,396.662994 1919.442627,418.935364 
	C1912.322754,431.566345 1903.824951,443.245941 1891.969360,451.978638 
	C1872.717163,466.159454 1851.213257,466.120087 1829.094849,461.512787 
	C1824.419800,460.538971 1819.845825,459.080444 1815.225220,457.845215 
M1864.637939,351.881714 
	C1865.654663,349.995331 1866.882446,348.191071 1867.653320,346.209106 
	C1872.319214,334.213257 1873.039917,321.741913 1871.984497,308.238464 
	C1870.005249,301.469055 1868.025879,294.699677 1865.973389,287.225006 
	C1864.300049,284.809143 1863.058716,281.866302 1860.873535,280.074768 
	C1853.934692,274.386108 1845.007202,277.004883 1840.478638,286.179138 
	C1833.052734,301.223145 1831.965576,317.363403 1833.516602,333.732483 
	C1834.057251,339.438690 1835.127441,345.239594 1836.992065,350.639435 
	C1839.430054,357.699921 1844.074951,360.195557 1851.938843,359.850830 
	C1857.251953,359.371582 1861.592041,357.338562 1864.637939,351.881714 
z"
      />
      <path
        fill="#319FFF"
        opacity="1.000000"
        stroke="none"
        d="
M1197.901855,239.922546 
	C1203.363281,222.667664 1210.618896,206.212845 1222.431274,192.366226 
	C1244.953735,165.964996 1273.759766,157.743042 1307.334595,164.402405 
	C1321.585205,167.228912 1333.854614,173.817734 1344.718262,184.258362 
	C1354.597046,196.025482 1362.244019,208.339600 1367.002808,222.394806 
	C1375.686646,248.042419 1376.410767,274.799866 1377.831665,301.401398 
	C1378.797852,319.486237 1377.310059,337.518982 1374.064209,355.399078 
	C1368.815552,384.311981 1359.899414,411.655060 1341.813843,435.413086 
	C1323.969238,458.854645 1300.449585,467.729370 1271.704346,463.040680 
	C1263.599854,461.718781 1255.683350,459.243988 1247.680664,457.297974 
	C1225.830933,446.790497 1213.552124,428.669952 1205.912720,406.307007 
	C1191.070435,362.859375 1187.007812,318.391724 1191.883911,272.084412 
	C1193.926392,260.801727 1195.914185,250.362137 1197.901855,239.922546 
M1304.939209,307.286560 
	C1302.969604,300.799713 1301.000122,294.312866 1299.030762,287.111481 
	C1295.108521,277.643982 1287.210571,274.178894 1279.824463,279.297882 
	C1276.842407,281.364716 1274.071533,284.673340 1272.737305,288.026764 
	C1264.762085,308.069244 1263.816040,328.644135 1269.721558,349.391541 
	C1271.898315,357.038513 1276.892700,360.094513 1285.853638,359.900482 
	C1290.661133,359.053467 1294.844116,357.265198 1297.640137,352.045654 
	C1305.769775,338.342865 1306.695557,323.460846 1304.939209,307.286560 
z"
      />
      <path
        fill="#319FFF"
        opacity="1.000000"
        stroke="none"
        d="
M2037.692871,457.283508 
	C2015.844971,446.769165 2003.540283,428.628906 1995.959595,406.270325 
	C1982.795166,367.442657 1978.008423,327.671112 1980.702148,286.832458 
	C1982.358032,261.725830 1986.667725,237.380096 1998.083252,214.635345 
	C2010.109009,190.674759 2027.303955,172.150879 2053.900879,165.361542 
	C2083.491943,157.807968 2111.007324,162.559799 2134.729004,184.230438 
	C2144.539307,195.840698 2152.148682,208.001511 2156.872803,221.888702 
	C2165.425293,247.031189 2166.418945,273.274994 2167.834229,299.388855 
	C2168.850586,318.143158 2167.445312,336.842621 2164.093018,355.400085 
	C2158.865723,384.337341 2149.908691,411.635468 2131.840332,435.428162 
	C2113.977539,458.950165 2090.434570,467.664734 2061.720215,463.038910 
	C2053.612305,461.732727 2045.696655,459.234833 2037.692871,457.283508 
M2088.308594,351.381683 
	C2095.519287,337.718842 2096.967285,323.185333 2094.968506,307.262817 
	C2092.989990,300.777832 2091.011719,294.292847 2089.038574,287.094788 
	C2087.098389,281.454620 2083.401855,277.433624 2077.321289,277.151520 
	C2071.122070,276.863861 2066.648193,280.279724 2064.006592,285.897766 
	C2054.072510,307.023987 2054.022217,328.801605 2060.171875,350.829041 
	C2061.942383,357.171143 2067.488037,360.080170 2075.855957,359.900940 
	C2081.123535,359.126953 2085.249023,356.653015 2088.308594,351.381683 
z"
      />
      <path
        fill="#329FFF"
        opacity="1.000000"
        stroke="none"
        d="
M731.777283,455.292297 
	C729.119080,455.233032 726.461243,455.148438 723.802673,455.119446 
	C717.054749,455.045837 716.071533,454.324921 716.190430,447.766907 
	C716.464539,432.638855 716.922852,417.513306 717.416260,402.390320 
	C717.779480,391.258606 718.518860,380.136505 718.735962,369.003204 
	C719.267578,341.737915 719.644470,314.469147 719.951782,287.200287 
	C720.001709,282.770294 719.377014,278.332672 719.123596,272.992645 
	C719.110596,268.735413 719.038147,265.384155 718.992737,261.568665 
	C719.019836,257.870697 719.019836,254.636902 719.019836,252.043640 
	C702.073669,252.043640 686.026367,252.043640 669.513916,252.012848 
	C663.959900,252.080826 658.871033,252.179611 653.782166,252.278412 
	C651.197021,252.313004 648.611938,252.347610 645.225464,252.323151 
	C639.537109,251.629730 637.820374,248.821945 637.952148,243.976349 
	C638.467896,225.008926 638.743958,206.034988 639.109131,187.063461 
	C639.088257,182.741791 639.117493,178.419113 639.032043,174.098724 
	C638.950867,169.995850 640.712280,167.800735 644.980225,167.984039 
	C645.146240,167.991165 645.313110,167.973587 645.479614,167.972717 
	C689.437134,167.743042 733.394470,167.482239 777.352173,167.300339 
	C794.489380,167.229416 811.627380,167.380798 828.764771,167.343460 
	C837.579590,167.324265 841.979126,170.128937 843.271851,177.796616 
	C843.141602,182.424881 843.088989,186.203552 843.003784,190.416275 
	C842.664185,199.315765 842.126160,207.781067 842.120605,216.246704 
	C842.115845,223.546188 842.863525,230.843079 843.014893,238.146561 
	C843.214478,247.777496 839.960388,250.994492 830.250061,251.010651 
	C807.918335,251.047836 785.586426,251.028229 763.254639,251.031586 
	C761.325684,251.031876 759.396790,251.031631 757.397400,251.031631 
	C756.923401,260.397034 756.089661,268.998749 756.117493,277.597687 
	C756.297485,333.228882 756.668274,388.859436 756.968872,444.490234 
	C757.024353,454.757324 757.008179,454.757416 746.473999,455.119751 
	C741.267578,455.154022 736.522400,455.223175 731.777283,455.292297 
z"
      />
      <path
        fill="#329FFF"
        opacity="1.000000"
        stroke="none"
        d="
M977.712402,252.368927 
	C974.391785,252.298584 971.047974,252.448685 967.756409,252.102188 
	C963.628235,251.667648 961.737244,249.127136 961.811646,244.926727 
	C962.226929,221.456451 962.663269,197.985870 962.887207,174.513443 
	C962.932068,169.800842 964.729187,167.949005 969.391907,167.930939 
	C1012.855164,167.762634 1056.317871,167.455215 1099.781128,167.271118 
	C1117.420166,167.196396 1135.060181,167.370834 1152.699585,167.344025 
	C1161.477417,167.330688 1165.854004,170.167084 1167.137939,177.895874 
	C1167.048096,182.497330 1167.004883,186.250046 1166.684082,190.308685 
	C1166.283081,192.429108 1166.021606,194.246429 1166.058716,196.057632 
	C1166.348389,210.173462 1166.757935,224.287003 1167.002808,238.403473 
	C1167.162476,247.615784 1163.825073,250.917465 1154.430786,251.003265 
	C1145.942383,251.080811 1137.452881,251.029709 1128.963867,251.030975 
	C1114.482544,251.033112 1100.001343,251.015945 1085.520020,251.050049 
	C1083.484741,251.054840 1081.010986,250.359131 1080.916748,253.897385 
	C1080.824463,257.366058 1080.173828,260.820892 1080.099976,264.288849 
	C1079.699707,283.064850 1079.252319,301.842072 1079.131470,320.620880 
	C1079.068604,330.407776 1079.779907,340.197083 1079.906006,349.988312 
	C1080.310791,381.414307 1080.593750,412.841888 1080.949219,444.268585 
	C1081.077637,455.627136 1081.013306,454.965271 1070.472534,455.123291 
	C1065.255737,455.172943 1060.500122,455.258942 1055.744507,455.344971 
	C1052.421997,455.264282 1049.095337,455.042267 1045.778076,455.138885 
	C1041.712646,455.257355 1039.999023,453.311798 1040.057373,449.414246 
	C1040.154541,442.926697 1040.105103,436.434967 1040.315674,429.951416 
	C1041.205933,402.523224 1042.351685,375.101959 1043.047241,347.669159 
	C1043.586792,326.388214 1043.631104,305.094177 1043.808228,283.805206 
	C1043.835938,280.489380 1043.397705,277.169678 1043.135376,272.978973 
	C1043.032104,268.765778 1042.968628,265.425354 1042.942505,261.616760 
	C1042.979858,257.907501 1042.979858,254.666351 1042.979858,252.064758 
	C1026.081665,252.064758 1010.025635,252.064758 993.506470,252.029602 
	C987.933044,252.119263 982.822693,252.244095 977.712402,252.368927 
z"
      />
      <path
        fill="#33A0FF"
        opacity="1.000000"
        stroke="none"
        d="
M601.929443,458.079468 
	C598.434570,458.065002 594.933350,457.912109 591.446655,458.073761 
	C587.713501,458.246826 586.513916,456.568146 586.567383,452.984680 
	C587.388916,397.902008 587.949829,342.814789 588.975403,287.736115 
	C589.483398,260.456635 590.964844,233.196487 591.691833,205.919235 
	C591.952759,196.128159 591.233826,186.313477 591.068542,176.507797 
	C590.908875,167.042450 593.032654,164.934921 602.243713,165.087036 
	C604.074463,165.117264 605.905212,165.149002 607.735779,165.188675 
	C616.711426,165.383209 617.227051,165.951157 616.578979,175.899658 
	C615.577698,193.582565 614.743530,210.510452 614.023926,227.443192 
	C613.621582,236.911713 613.408691,246.388321 613.111572,255.861298 
	C612.423279,277.805389 611.222839,299.748138 611.164856,321.693878 
	C611.072083,356.827087 611.700317,391.962738 612.118958,427.096222 
	C612.186157,432.738312 612.554688,438.394043 613.122253,444.009247 
	C614.188660,454.557983 612.307800,457.049744 601.929443,458.079468 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M859.115784,188.982712 
	C863.144226,190.201843 867.480408,190.851791 871.107422,192.813217 
	C874.227173,194.500336 874.705444,197.806732 874.471863,201.858994 
	C873.728943,214.747223 873.893066,227.693970 873.916565,240.617722 
	C873.929871,247.921005 874.636597,255.228317 874.512451,262.524994 
	C874.396179,269.352966 869.619141,272.967163 861.377563,272.982910 
	C839.213928,273.025269 817.050110,272.999054 794.886414,273.000031 
	C792.900391,273.000092 790.914429,273.000000 788.735107,273.000000 
	C788.448730,274.193848 788.039001,275.116150 788.032776,276.041199 
	C787.684692,327.499573 787.290771,378.957886 787.107178,430.416931 
	C787.059692,443.713135 787.738770,457.010773 787.971741,470.309692 
	C788.074707,476.185303 787.195862,476.973511 781.369446,476.992157 
	C773.203918,477.018280 765.038269,476.999359 756.872681,476.998627 
	C747.744446,476.997772 747.131958,476.375824 747.003540,466.475922 
	C746.994141,465.957153 747.027710,465.775421 747.489380,465.852570 
	C753.949890,465.955170 759.966370,465.707031 765.942200,466.091064 
	C770.878662,466.408295 772.232483,464.487671 772.139221,459.764374 
	C771.639832,434.468262 771.095642,409.166565 771.137695,383.867706 
	C771.203308,344.403564 771.724670,304.940186 772.070068,265.476654 
	C772.078430,264.519165 772.286743,263.563477 772.473511,262.011383 
	C774.467468,262.011383 776.247192,262.011658 778.026978,262.011353 
	C800.858276,262.007477 823.689697,262.039551 846.520813,261.980530 
	C854.823181,261.959076 859.138367,257.576538 858.953369,249.307510 
	C858.834656,244.003647 858.035095,238.706497 858.066040,233.409424 
	C858.152649,218.616074 858.512573,203.824310 858.851685,189.014603 
	C858.938782,188.997284 859.115784,188.982712 859.115784,188.982712 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M1197.621460,239.625671 
	C1195.914185,250.362137 1193.926392,260.801727 1191.849854,271.668762 
	C1184.479736,272.397461 1177.199829,272.904938 1169.916626,272.958801 
	C1152.609253,273.086792 1135.300537,273.000000 1117.992310,273.000000 
	C1116.209717,273.000000 1114.427246,273.000000 1112.424927,273.000000 
	C1111.939087,280.642395 1111.114868,287.709259 1111.100098,294.777832 
	C1110.990601,347.034943 1111.010132,399.292389 1111.084351,451.549622 
	C1111.093506,458.005981 1111.604614,464.465179 1111.995728,470.916260 
	C1112.271729,475.468170 1110.201050,477.153320 1105.743896,477.052612 
	C1097.427002,476.864685 1089.102661,476.999359 1080.781494,476.998566 
	C1071.577637,476.997711 1071.076904,476.488251 1070.990601,466.527893 
	C1070.987061,466.004425 1071.016724,465.817444 1071.480225,465.884888 
	C1077.940063,465.970245 1083.954468,465.709106 1089.927368,466.092499 
	C1094.894165,466.411346 1096.219238,464.433472 1096.108643,459.738464 
	C1095.611572,438.619598 1095.078857,417.493530 1095.131348,396.371460 
	C1095.240234,352.594421 1095.708374,308.818237 1096.062744,265.041992 
	C1096.070190,264.117615 1096.487061,263.196564 1096.777222,262.011383 
	C1098.786621,262.011383 1100.607300,262.011597 1102.427979,262.011353 
	C1124.748657,262.008453 1147.069580,262.042358 1169.390137,261.985931 
	C1179.540283,261.960297 1184.027222,256.763336 1182.923218,246.793930 
	C1182.449463,242.515793 1182.018433,238.200424 1182.046509,233.905121 
	C1182.144531,218.955368 1182.452148,204.007004 1182.771484,189.034210 
	C1182.862793,189.010330 1183.050781,188.992661 1183.050781,188.992661 
	C1185.822266,189.414566 1188.748535,189.412704 1191.329712,190.355469 
	C1195.432861,191.853973 1198.307251,194.504913 1198.087524,199.592773 
	C1197.586670,211.184158 1197.340576,222.786865 1197.040771,234.386261 
	C1196.998291,236.030090 1197.234131,237.681107 1197.621460,239.625671 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M1695.161255,209.878845 
	C1699.369751,212.701065 1704.070679,215.001175 1707.707275,218.429993 
	C1731.825439,241.170471 1747.232666,268.809448 1751.847900,301.753510 
	C1754.140869,318.121155 1755.792725,334.747803 1755.670898,351.242065 
	C1755.516479,372.170197 1752.459106,392.816254 1742.673950,411.992645 
	C1735.467163,426.115936 1724.717651,436.933685 1712.939331,447.023407 
	C1691.715210,465.204498 1666.133545,471.950653 1639.187866,475.009460 
	C1633.761597,475.625397 1628.277588,475.856995 1622.813477,475.989868 
	C1606.024170,476.398102 1589.232300,476.722015 1572.439941,476.976440 
	C1564.853149,477.091400 1562.621948,474.838684 1562.410645,466.479034 
	C1574.495972,465.711609 1586.139282,464.889160 1597.755371,465.174530 
	C1617.183472,465.651825 1636.095337,463.210205 1654.842651,458.121765 
	C1682.189331,450.699341 1703.541748,434.724976 1720.152832,412.428162 
	C1734.344971,393.378448 1738.812378,370.815033 1739.876953,347.632416 
	C1740.784546,327.865692 1739.816284,308.144135 1736.076050,288.601440 
	C1732.057007,267.602020 1725.003296,247.903168 1712.103394,230.663315 
	C1706.753052,223.512894 1700.825928,216.794022 1695.161255,209.878845 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M1694.861328,209.725586 
	C1700.825928,216.794022 1706.753052,223.512894 1712.103394,230.663315 
	C1725.003296,247.903168 1732.057007,267.602020 1736.076050,288.601440 
	C1739.816284,308.144135 1740.784546,327.865692 1739.876953,347.632416 
	C1738.812378,370.815033 1734.344971,393.378448 1720.152832,412.428162 
	C1703.541748,434.724976 1682.189331,450.699341 1654.842651,458.121765 
	C1636.095337,463.210205 1617.183472,465.651825 1597.755371,465.174530 
	C1586.139282,464.889160 1574.495972,465.711609 1562.389893,466.080139 
	C1561.914917,466.124176 1561.926758,466.055359 1561.958740,465.577759 
	C1561.987427,461.746613 1561.984253,458.393097 1561.981201,455.039551 
	C1575.778564,454.692413 1589.656982,455.172028 1603.357056,453.830902 
	C1634.594482,450.773041 1663.499390,442.294861 1687.789429,420.271973 
	C1708.037598,401.913788 1718.951050,379.591522 1722.745605,353.387695 
	C1725.385986,335.154358 1725.118530,316.752960 1723.264160,298.322144 
	C1721.627197,282.052826 1718.860474,266.163391 1713.120728,250.826492 
	C1706.441040,232.977982 1696.484375,217.227631 1683.189087,203.575317 
	C1682.041626,202.396805 1681.065430,201.051270 1679.970215,199.447037 
	C1684.807617,202.597885 1689.684570,206.085083 1694.861328,209.725586 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M1360.146118,194.885406 
	C1366.221680,199.601578 1373.280518,203.472702 1378.192749,209.188629 
	C1394.494995,228.157990 1402.109009,250.861282 1405.126221,275.505798 
	C1407.916138,298.293976 1409.849731,321.129211 1408.842285,344.034332 
	C1407.453491,375.615265 1401.374268,406.313507 1386.968384,434.766907 
	C1378.499878,451.493530 1367.982300,466.858856 1351.506104,476.942688 
	C1337.760010,485.355743 1322.602661,487.835449 1306.928223,485.295074 
	C1296.872314,483.665344 1286.914795,480.915009 1277.234985,477.698730 
	C1272.535278,476.137207 1268.574219,472.353149 1264.574097,469.250671 
	C1277.486450,472.570557 1290.164062,475.318176 1303.486206,475.109131 
	C1325.023071,474.771118 1341.600952,465.272217 1354.976685,449.077606 
	C1367.976807,433.338104 1376.807983,415.447906 1382.531616,395.946381 
	C1388.717041,374.871094 1393.223511,353.468567 1392.949341,331.404846 
	C1392.732666,313.984070 1392.431641,296.506287 1390.820923,279.179047 
	C1389.493896,264.902924 1386.989868,250.613205 1383.552246,236.688751 
	C1379.635742,220.824829 1370.584473,207.351028 1360.146118,194.885406 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M2150.167480,194.875565 
	C2156.241211,199.597168 2163.277588,203.486465 2168.212891,209.192688 
	C2184.593750,228.132736 2192.095459,250.857529 2195.155273,275.510193 
	C2198.046387,298.803192 2200.175781,322.167084 2198.781006,345.537659 
	C2196.502930,383.723145 2188.713867,420.502289 2166.190186,452.706390 
	C2155.368896,468.178680 2142.126221,480.581573 2123.106201,484.282135 
	C2099.402832,488.893829 2076.904785,485.008240 2056.421143,471.628876 
	C2055.607178,471.097015 2054.992432,470.260071 2054.582520,469.242523 
	C2067.179199,472.433990 2079.518311,475.143951 2092.498535,475.126587 
	C2115.091797,475.096405 2132.199707,464.951599 2145.998535,447.969116 
	C2159.977539,430.764557 2168.775879,410.958771 2174.478027,389.757660 
	C2179.742188,370.185516 2183.579590,350.315002 2183.013428,329.901978 
	C2182.534668,312.646545 2182.430176,295.333038 2180.814697,278.171936 
	C2179.502197,264.227936 2176.951904,250.283569 2173.573242,236.681473 
	C2169.631592,220.811783 2160.624268,207.331100 2150.167480,194.875565 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M1928.144409,195.886414 
	C1933.502441,199.875610 1939.797241,203.058319 1944.050903,207.998444 
	C1959.613647,226.072876 1968.604614,247.270447 1971.022827,271.064087 
	C1972.561890,286.207794 1973.654663,301.396210 1975.055908,316.554596 
	C1978.031738,348.746979 1973.485474,380.121826 1963.730957,410.677185 
	C1956.370605,433.732605 1944.979614,454.699615 1926.450684,471.031342 
	C1916.126587,480.131042 1903.860962,485.134949 1890.289429,485.829987 
	C1871.097046,486.812897 1852.518555,483.969849 1835.865967,473.384827 
	C1833.796509,472.069458 1831.987549,470.344452 1830.359253,468.464722 
	C1832.381836,468.727356 1834.090210,469.373169 1835.825806,469.935822 
	C1851.726807,475.090912 1867.900757,476.857391 1884.378296,473.342041 
	C1904.045166,469.146362 1917.430420,456.401001 1928.312988,440.430298 
	C1949.479004,409.368256 1957.541748,374.105133 1959.764038,337.422028 
	C1961.422363,310.048523 1959.550049,282.649536 1954.961548,255.535446 
	C1952.679932,242.053482 1949.128052,228.915329 1942.024902,217.081848 
	C1937.681763,209.846390 1932.789917,202.940338 1928.144409,195.886414 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M1927.841675,195.741669 
	C1932.789917,202.940338 1937.681763,209.846390 1942.024902,217.081848 
	C1949.128052,228.915329 1952.679932,242.053482 1954.961548,255.535446 
	C1959.550049,282.649536 1961.422363,310.048523 1959.764038,337.422028 
	C1957.541748,374.105133 1949.479004,409.368256 1928.312988,440.430298 
	C1917.430420,456.401001 1904.045166,469.146362 1884.378296,473.342041 
	C1867.900757,476.857391 1851.726807,475.090912 1835.825806,469.935822 
	C1834.090210,469.373169 1832.381836,468.727356 1830.277344,468.137695 
	C1825.032471,464.944580 1820.171143,461.733887 1815.267456,458.184204 
	C1819.845825,459.080444 1824.419800,460.538971 1829.094849,461.512787 
	C1851.213257,466.120087 1872.717163,466.159454 1891.969360,451.978638 
	C1903.824951,443.245941 1912.322754,431.566345 1919.442627,418.935364 
	C1931.996948,396.662994 1938.860229,372.464966 1941.957642,347.229279 
	C1943.488892,334.754364 1944.988647,322.130676 1944.726685,309.611450 
	C1944.214233,285.119415 1943.161621,260.567261 1937.863037,236.504684 
	C1933.648682,217.365860 1925.213867,200.226456 1912.250977,185.043381 
	C1917.328979,188.325272 1922.433960,191.961105 1927.841675,195.741669 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M2149.888184,194.692902 
	C2160.624268,207.331100 2169.631592,220.811783 2173.573242,236.681473 
	C2176.951904,250.283569 2179.502197,264.227936 2180.814697,278.171936 
	C2182.430176,295.333038 2182.534668,312.646545 2183.013428,329.901978 
	C2183.579590,350.315002 2179.742188,370.185516 2174.478027,389.757660 
	C2168.775879,410.958771 2159.977539,430.764557 2145.998535,447.969116 
	C2132.199707,464.951599 2115.091797,475.096405 2092.498535,475.126587 
	C2079.518311,475.143951 2067.179199,472.433990 2054.484619,468.915833 
	C2048.704590,465.244171 2043.320068,461.576385 2037.814209,457.596039 
	C2045.696655,459.234833 2053.612305,461.732727 2061.720215,463.038910 
	C2090.434570,467.664734 2113.977539,458.950165 2131.840332,435.428162 
	C2149.908691,411.635468 2158.865723,384.337341 2164.093018,355.400085 
	C2167.445312,336.842621 2168.850586,318.143158 2167.834229,299.388855 
	C2166.418945,273.274994 2165.425293,247.031189 2156.872803,221.888702 
	C2152.148682,208.001511 2144.539307,195.840698 2134.975342,184.457794 
	C2139.825195,187.584717 2144.717041,191.047470 2149.888184,194.692902 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M1359.867676,194.702850 
	C1370.584473,207.351028 1379.635742,220.824829 1383.552246,236.688751 
	C1386.989868,250.613205 1389.493896,264.902924 1390.820923,279.179047 
	C1392.431641,296.506287 1392.732666,313.984070 1392.949341,331.404846 
	C1393.223511,353.468567 1388.717041,374.871094 1382.531616,395.946381 
	C1376.807983,415.447906 1367.976807,433.338104 1354.976685,449.077606 
	C1341.600952,465.272217 1325.023071,474.771118 1303.486206,475.109131 
	C1290.164062,475.318176 1277.486450,472.570557 1264.472656,468.924927 
	C1258.691528,465.256989 1253.307251,461.590210 1247.801880,457.610687 
	C1255.683350,459.243988 1263.599854,461.718781 1271.704346,463.040680 
	C1300.449585,467.729370 1323.969238,458.854645 1341.813843,435.413086 
	C1359.899414,411.655060 1368.815552,384.311981 1374.064209,355.399078 
	C1377.310059,337.518982 1378.797852,319.486237 1377.831665,301.401398 
	C1376.410767,274.799866 1375.686646,248.042419 1367.002808,222.394806 
	C1362.244019,208.339600 1354.597046,196.025482 1344.964844,184.486694 
	C1349.808594,187.608322 1354.698853,191.064331 1359.867676,194.702850 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M638.675476,187.042999 
	C638.743958,206.034988 638.467896,225.008926 637.952148,243.976349 
	C637.820374,248.821945 639.537109,251.629730 644.806213,252.416656 
	C644.799683,263.843353 644.341858,275.115601 644.039001,286.391998 
	C643.615417,302.158752 643.212402,317.927246 642.971558,333.697571 
	C642.588440,358.781067 642.190002,383.866241 642.109924,408.951691 
	C642.069214,421.725311 642.562805,434.507233 643.097229,447.273529 
	C643.394226,454.367432 644.155334,461.449707 644.924133,468.513611 
	C645.560059,474.356323 643.477478,477.806183 637.677368,478.997192 
	C634.462463,479.657410 631.118103,479.912292 627.828430,479.942261 
	C616.757263,480.043182 618.014893,480.849854 617.985596,469.492249 
	C617.993591,468.946381 618.031128,468.749420 618.414612,468.818848 
	C628.497986,466.889038 629.419983,465.855499 628.962097,455.970856 
	C628.447876,444.871216 627.460999,433.789581 627.121460,422.686615 
	C626.577515,404.900452 626.005676,387.099640 626.165588,369.311493 
	C626.352234,348.549896 627.380981,327.797058 627.915955,307.037109 
	C628.387207,288.748352 628.682068,270.455078 629.053650,252.163788 
	C629.084106,250.665375 629.069885,249.165176 629.136169,247.668472 
	C630.032227,227.448441 630.938843,207.228882 631.937256,186.995285 
	C632.031555,186.981354 632.221436,186.963623 632.221436,186.963623 
	C634.228271,186.983261 636.235046,187.002899 638.675476,187.042999 
z"
      />
      <path
        fill="#96CEFF"
        opacity="1.000000"
        stroke="none"
        d="
M601.980957,458.524719 
	C612.307800,457.049744 614.188660,454.557983 613.122253,444.009247 
	C612.554688,438.394043 612.186157,432.738312 612.118958,427.096222 
	C611.700317,391.962738 611.072083,356.827087 611.164856,321.693878 
	C611.222839,299.748138 612.423279,277.805389 613.111572,255.861298 
	C613.408691,246.388321 613.621582,236.911713 614.023926,227.443192 
	C614.743530,210.510452 615.577698,193.582565 616.619873,176.338654 
	C618.697266,176.032928 620.518921,176.036545 622.340393,176.050201 
	C632.145447,176.123749 632.145447,176.124619 632.161499,186.522659 
	C632.221436,186.963623 632.031555,186.981354 631.538574,186.991882 
	C625.005676,187.112717 622.223816,189.542969 622.094727,195.680283 
	C622.001160,200.126907 622.987305,204.593399 622.933472,209.044281 
	C622.813049,218.998810 622.359680,228.949661 622.013367,238.901016 
	C621.355347,257.806183 620.556458,276.707367 620.042297,295.616364 
	C619.549866,313.725220 619.223816,331.840637 619.037598,349.955353 
	C618.630493,389.552643 618.358398,429.151306 618.031128,468.749420 
	C618.031128,468.749420 617.993591,468.946381 617.986206,469.047180 
	C614.868958,469.096558 611.759399,469.019714 608.649353,468.998932 
	C602.128845,468.955292 602.037048,468.874786 602.047302,462.409821 
	C602.049072,461.263184 602.037720,460.116577 601.980957,458.524719 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M1042.905029,262.084930 
	C1042.968628,265.425354 1043.032104,268.765778 1043.033081,272.560425 
	C1029.684326,273.348480 1016.399475,273.757690 1003.111511,273.989655 
	C994.250977,274.144379 992.966980,272.766937 992.990234,263.512451 
	C992.988892,262.990173 993.020935,262.798523 993.483948,262.867615 
	C1010.266296,262.652771 1026.585693,262.368866 1042.905029,262.084930 
z"
      />
      <path
        fill="#C1E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M718.965698,262.032867 
	C719.038147,265.384155 719.110596,268.735413 719.102356,272.537659 
	C705.751770,273.331512 692.483215,273.750336 679.211487,273.990112 
	C670.582336,274.146027 669.018982,272.493958 669.003113,263.466003 
	C668.998596,262.949890 669.040466,262.763458 669.500610,262.840088 
	C686.295776,262.622101 702.630737,262.327484 718.965698,262.032867 
z"
      />
      <path
        fill="#96CEFF"
        opacity="1.000000"
        stroke="none"
        d="
M653.773254,252.640778 
	C658.871033,252.179611 663.959900,252.080826 669.521057,252.451508 
	C669.675720,256.201813 669.358093,259.482635 669.040466,262.763458 
	C669.040466,262.763458 668.998596,262.949890 668.987793,263.045502 
	C666.351990,263.118500 663.725891,263.136719 661.102234,263.065094 
	C654.789917,262.892853 652.141541,259.240570 653.773254,252.640778 
z"
      />
      <path
        fill="#96CEFF"
        opacity="1.000000"
        stroke="none"
        d="
M977.554993,252.820770 
	C982.822693,252.244095 987.933044,252.119263 993.513794,252.447876 
	C993.663086,256.200409 993.341980,259.499451 993.020935,262.798523 
	C993.020935,262.798523 992.988892,262.990173 992.980103,263.087891 
	C990.651611,263.150055 988.332092,263.103912 986.012268,263.080994 
	C978.773132,263.009430 976.670776,260.619690 977.554993,252.820770 
z"
      />
      <path
        fill="#96CEFF"
        opacity="1.000000"
        stroke="none"
        d="
M1166.961792,190.002762 
	C1167.004883,186.250046 1167.048096,182.497330 1167.172852,178.274963 
	C1170.143188,178.271622 1173.136230,178.408264 1175.896362,179.280991 
	C1180.107544,180.612442 1182.788940,183.523468 1183.013184,188.607986 
	C1183.050781,188.992661 1182.862793,189.010330 1182.382080,189.035416 
	C1176.921509,189.374588 1171.941650,189.688675 1166.961792,190.002762 
z"
      />
      <path
        fill="#96CEFF"
        opacity="1.000000"
        stroke="none"
        d="
M731.819824,455.715851 
	C736.522400,455.223175 741.267578,455.154022 746.499023,455.559814 
	C746.999451,459.281647 747.013611,462.528534 747.027710,465.775421 
	C747.027710,465.775421 746.994141,465.957153 746.986450,466.049866 
	C744.367615,466.100342 741.756531,466.042694 739.145264,466.018799 
	C732.716736,465.959991 732.076477,465.346741 731.953186,459.075928 
	C731.933960,458.096893 731.893188,457.118225 731.819824,455.715851 
z"
      />
      <path
        fill="#96CEFF"
        opacity="1.000000"
        stroke="none"
        d="
M1055.779785,455.765259 
	C1060.500122,455.258942 1065.255737,455.172943 1070.496826,455.563293 
	C1070.993774,459.298920 1071.005249,462.558197 1071.016724,465.817444 
	C1071.016724,465.817444 1070.987061,466.004425 1070.978027,466.099579 
	C1068.194458,466.134705 1065.420288,466.057465 1062.645508,466.018188 
	C1056.690918,465.933868 1056.023438,465.258514 1055.901367,459.124176 
	C1055.881836,458.144409 1055.844238,457.165070 1055.779785,455.765259 
z"
      />
      <path
        fill="#96CEFF"
        opacity="1.000000"
        stroke="none"
        d="
M843.036438,189.982208 
	C843.088989,186.203552 843.141602,182.424881 843.317383,178.175262 
	C845.884155,178.133316 848.347534,178.472733 850.767029,179.010132 
	C855.677368,180.100769 858.681274,182.984039 859.071350,188.597397 
	C859.115784,188.982712 858.938782,188.997284 858.453369,189.019836 
	C852.990784,189.355682 848.013611,189.668945 843.036438,189.982208 
z"
      />
      <path
        fill="#96CEFF"
        opacity="1.000000"
        stroke="none"
        d="
M1561.516602,455.006744 
	C1561.984253,458.393097 1561.987427,461.746613 1561.988525,465.559692 
	C1561.003662,466.016724 1560.020630,466.013702 1559.037720,466.011871 
	C1549.151123,465.993408 1548.669312,465.621490 1545.908203,455.562744 
	C1550.912109,455.123688 1555.982056,455.048798 1561.516602,455.006744 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M109.110596,142.900452 
	C136.823822,166.930618 168.278351,173.816238 202.472015,162.308838 
	C237.341003,150.574173 258.175476,125.468010 265.128113,89.148438 
	C265.440796,87.514854 265.776886,85.875755 265.930054,84.223274 
	C266.850769,74.293129 266.828522,74.273712 276.535889,71.339386 
	C282.425476,69.559082 288.333740,67.840263 294.238831,66.111603 
	C294.544434,66.022125 294.899048,66.100060 295.923004,66.100060 
	C328.635315,175.615997 361.409637,285.339569 394.377106,395.709686 
	C381.600311,399.572144 369.091705,403.353546 356.001099,407.310913 
	C361.036835,424.216370 365.998291,440.872528 371.101898,458.005737 
	C384.024506,454.176910 396.557373,450.463593 409.564392,446.609772 
	C417.171326,472.070801 424.671265,497.173828 432.300018,522.707886 
	C423.035828,525.502319 414.196411,528.351135 405.220337,530.670959 
	C403.936066,531.002930 401.529358,529.130798 400.421051,527.682556 
	C384.696991,507.135803 364.194458,494.696777 338.568787,490.838257 
	C289.574829,483.461182 242.948227,515.887756 233.003662,564.364136 
	C231.772415,570.366028 231.279434,576.519409 230.381424,583.060913 
	C221.240753,585.784729 211.727005,588.619751 201.649719,591.622681 
	C194.003830,566.052124 186.503128,540.967163 178.896774,515.528809 
	C187.467026,512.919128 195.694229,510.413849 204.478760,507.738892 
	C199.435410,490.856354 194.501663,474.340729 189.373444,457.174103 
	C180.764221,459.647064 172.531570,462.011841 163.680267,464.554352 
	C130.802063,354.482483 98.032654,244.774796 65.120934,134.590698 
	C74.388580,131.787704 83.378014,128.985474 92.442123,126.450226 
	C93.399338,126.182487 95.102509,127.200996 95.936699,128.105179 
	C100.333923,132.871323 104.563309,137.792297 109.110596,142.900452 
M191.571320,327.198364 
	C192.402008,332.867188 193.430008,338.515411 194.025497,344.208832 
	C195.663010,359.864471 197.143875,375.536713 198.629944,391.207794 
	C199.009583,395.211029 200.628098,398.261719 204.601501,399.577362 
	C208.405640,400.836975 211.318085,399.146912 214.038986,396.602020 
	C226.572144,384.879608 239.388168,373.448029 251.626999,361.427734 
	C257.223541,355.931061 261.954468,353.141785 270.599274,355.625153 
	C287.347198,360.436279 304.698273,363.111938 321.686127,367.148956 
	C325.973450,368.167755 329.072205,367.655426 331.888275,364.578400 
	C334.916809,361.269226 334.329010,357.843658 332.541443,354.042297 
	C323.971252,335.817627 315.523010,317.534698 307.217896,299.188049 
	C306.575684,297.769287 306.794617,295.367004 307.589203,293.981567 
	C317.664368,276.414459 327.899384,258.938751 338.145325,241.470108 
	C339.919800,238.444687 341.562378,234.995331 338.643555,232.406601 
	C336.204590,230.243408 332.650085,229.102661 329.398773,228.185776 
	C327.662781,227.696228 325.528290,228.679810 323.564728,228.927902 
	C307.825470,230.916626 292.113556,233.222046 276.321411,234.617111 
	C272.644562,234.941925 268.333740,238.250977 264.644379,234.115875 
	C258.133026,226.817917 251.518707,219.611420 245.041824,212.283264 
	C237.481323,203.729004 230.216431,194.900314 222.353851,186.637360 
	C220.789291,184.993118 217.054245,184.725067 214.408279,184.934875 
	C207.843094,185.455521 208.116104,191.322372 207.208679,195.745239 
	C203.380539,214.403748 199.874237,233.128235 196.065857,251.790970 
	C195.751053,253.333649 194.353485,255.258041 192.956146,255.885880 
	C174.822495,264.033539 156.608765,272.003693 138.386810,279.953461 
	C134.677277,281.571838 131.495560,283.645569 131.260681,288.067932 
	C131.009445,292.798187 133.994217,295.376556 137.873596,297.511688 
	C155.563858,307.248016 173.172272,317.133087 191.571320,327.198364 
M310.023163,440.408752 
	C308.251007,434.573425 306.478821,428.738129 304.610229,422.585388 
	C283.030212,429.034210 262.038025,435.307373 240.596893,441.714661 
	C245.726974,458.851715 250.707458,475.489105 255.802063,492.507599 
	C277.413574,486.044037 298.372955,479.775543 319.835419,473.356567 
	C316.495148,462.182190 313.357239,451.684875 310.023163,440.408752 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M1617.005737,356.237946 
	C1612.214844,359.955109 1607.423950,363.672272 1602.633057,367.389435 
	C1602.041748,367.122803 1601.450439,366.856201 1600.859131,366.589600 
	C1601.947266,329.633362 1603.035278,292.677155 1604.140503,255.141953 
	C1611.460083,256.951660 1618.232178,260.547607 1623.664307,266.502075 
	C1622.093628,266.634277 1620.847900,266.402771 1619.352295,266.124817 
	C1619.214600,267.610352 1619.044922,268.735046 1619.015625,269.863312 
	C1618.681030,282.765381 1618.350586,295.667603 1618.053345,308.570557 
	C1617.687256,324.459290 1617.353516,340.348785 1617.005737,356.237946 
z"
      />
      <path
        fill="#C0E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M1617.343262,356.143494 
	C1617.353516,340.348785 1617.687256,324.459290 1618.053345,308.570557 
	C1618.350586,295.667603 1618.681030,282.765381 1619.015625,269.863312 
	C1619.044922,268.735046 1619.214600,267.610352 1619.352295,266.124817 
	C1620.847900,266.402771 1622.093628,266.634277 1623.738770,266.835999 
	C1627.411499,270.212860 1630.684937,273.619446 1634.088379,277.623962 
	C1634.480591,278.481354 1634.743042,278.740875 1635.005493,279.000366 
	C1635.005493,279.000336 1635.000000,279.000000 1635.010498,279.465576 
	C1634.363525,297.160950 1633.677734,314.389832 1633.092285,331.622131 
	C1633.032959,333.364868 1633.579590,335.128204 1633.845093,336.881989 
	C1628.457031,343.270996 1623.068848,349.660004 1617.343262,356.143494 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M1633.965088,336.551331 
	C1633.579590,335.128204 1633.032959,333.364868 1633.092285,331.622131 
	C1633.677734,314.389832 1634.363525,297.160950 1635.010986,279.461731 
	C1645.609863,298.243073 1644.242554,317.302216 1633.965088,336.551331 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M1634.921387,278.726349 
	C1634.743042,278.740875 1634.480591,278.481354 1634.114746,277.956299 
	C1634.286743,277.944611 1634.562012,278.198456 1634.921387,278.726349 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M1851.188110,359.725922 
	C1844.074951,360.195557 1839.430054,357.699921 1836.992065,350.639435 
	C1835.127441,345.239594 1834.057251,339.438690 1833.516602,333.732483 
	C1831.965576,317.363403 1833.052734,301.223145 1840.478638,286.179138 
	C1845.007202,277.004883 1853.934692,274.386108 1860.873535,280.074768 
	C1863.058716,281.866302 1864.300049,284.809143 1865.700439,287.745880 
	C1862.088745,291.561951 1857.259644,294.282837 1855.722656,298.272156 
	C1852.549438,306.508545 1849.769897,315.351471 1849.323853,324.072510 
	C1848.718994,335.894257 1850.455811,347.835785 1851.188110,359.725922 
z"
      />
      <path
        fill="#C0E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M1851.563477,359.788391 
	C1850.455811,347.835785 1848.718994,335.894257 1849.323853,324.072510 
	C1849.769897,315.351471 1852.549438,306.508545 1855.722656,298.272156 
	C1857.259644,294.282837 1862.088745,291.561951 1865.736938,288.098511 
	C1868.025879,294.699677 1870.005249,301.469055 1871.625488,308.796265 
	C1870.453735,310.953156 1869.483887,312.492828 1868.853271,314.160797 
	C1864.197632,326.472717 1863.578003,339.309235 1864.034546,352.275665 
	C1861.592041,357.338562 1857.251953,359.371582 1851.563477,359.788391 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M1864.336182,352.078674 
	C1863.578003,339.309235 1864.197632,326.472717 1868.853271,314.160797 
	C1869.483887,312.492828 1870.453735,310.953156 1871.580322,309.211487 
	C1873.039917,321.741913 1872.319214,334.213257 1867.653320,346.209106 
	C1866.882446,348.191071 1865.654663,349.995331 1864.336182,352.078674 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M1285.113403,359.846832 
	C1276.892700,360.094513 1271.898315,357.038513 1269.721558,349.391541 
	C1263.816040,328.644135 1264.762085,308.069244 1272.737305,288.026764 
	C1274.071533,284.673340 1276.842407,281.364716 1279.824463,279.297882 
	C1287.210571,274.178894 1295.108521,277.643982 1298.836792,287.766052 
	C1293.869751,290.171509 1290.458740,293.453979 1288.555054,298.088196 
	C1280.268555,318.259796 1279.805664,338.900574 1285.113403,359.846832 
z"
      />
      <path
        fill="#C0E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M1285.483521,359.873657 
	C1279.805664,338.900574 1280.268555,318.259796 1288.555054,298.088196 
	C1290.458740,293.453979 1293.869751,290.171509 1298.836670,288.123322 
	C1301.000122,294.312866 1302.969604,300.799713 1304.630859,307.918915 
	C1303.476318,310.516449 1302.529541,312.445068 1301.800415,314.452728 
	C1297.329834,326.761841 1296.665039,339.557617 1297.037720,352.478973 
	C1294.844116,357.265198 1290.661133,359.053467 1285.483521,359.873657 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M1297.338867,352.262329 
	C1296.665039,339.557617 1297.329834,326.761841 1301.800415,314.452728 
	C1302.529541,312.445068 1303.476318,310.516449 1304.576416,308.336609 
	C1306.695557,323.460846 1305.769775,338.342865 1297.338867,352.262329 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M2075.115479,359.843933 
	C2067.488037,360.080170 2061.942383,357.171143 2060.171875,350.829041 
	C2054.022217,328.801605 2054.072510,307.023987 2064.006592,285.897766 
	C2066.648193,280.279724 2071.122070,276.863861 2077.321289,277.151520 
	C2083.401855,277.433624 2087.098389,281.454620 2088.845703,287.752960 
	C2085.824707,290.800293 2081.926270,292.631104 2080.341553,295.668884 
	C2069.614502,316.233002 2069.993652,337.886993 2075.115479,359.843933 
z"
      />
      <path
        fill="#C0E2FF"
        opacity="1.000000"
        stroke="none"
        d="
M2075.485840,359.872437 
	C2069.993652,337.886993 2069.614502,316.233002 2080.341553,295.668884 
	C2081.926270,292.631104 2085.824707,290.800293 2088.842773,288.109497 
	C2091.011719,294.292847 2092.989990,300.777832 2094.652832,307.904541 
	C2092.800049,312.893280 2090.841797,317.143097 2089.811279,321.607117 
	C2087.516357,331.548584 2085.773682,341.602722 2087.818848,351.863647 
	C2085.249023,356.653015 2081.123535,359.126953 2075.485840,359.872437 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M2088.063721,351.622681 
	C2085.773682,341.602722 2087.516357,331.548584 2089.811279,321.607117 
	C2090.841797,317.143097 2092.800049,312.893280 2094.591797,308.329407 
	C2096.967285,323.185333 2095.519287,337.718842 2088.063721,351.622681 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M843.003784,190.416260 
	C848.013611,189.668945 852.990784,189.355682 858.366333,189.037170 
	C858.512573,203.824310 858.152649,218.616074 858.066040,233.409424 
	C858.035095,238.706497 858.834656,244.003647 858.953369,249.307510 
	C859.138367,257.576538 854.823181,261.959076 846.520813,261.980530 
	C823.689697,262.039551 800.858276,262.007477 778.026978,262.011353 
	C776.247192,262.011658 774.467468,262.011383 772.473511,262.011383 
	C772.286743,263.563477 772.078430,264.519165 772.070068,265.476654 
	C771.724670,304.940186 771.203308,344.403564 771.137695,383.867706 
	C771.095642,409.166565 771.639832,434.468262 772.139221,459.764374 
	C772.232483,464.487671 770.878662,466.408295 765.942200,466.091064 
	C759.966370,465.707031 753.949890,465.955170 747.489380,465.852570 
	C747.013611,462.528534 746.999451,459.281647 746.960327,455.594696 
	C757.008179,454.757416 757.024353,454.757324 756.968872,444.490234 
	C756.668274,388.859436 756.297485,333.228882 756.117493,277.597687 
	C756.089661,268.998749 756.923401,260.397034 757.397400,251.031631 
	C759.396790,251.031631 761.325684,251.031876 763.254639,251.031586 
	C785.586426,251.028229 807.918335,251.047836 830.250061,251.010651 
	C839.960388,250.994492 843.214478,247.777496 843.014893,238.146561 
	C842.863525,230.843079 842.115845,223.546188 842.120605,216.246704 
	C842.126160,207.781067 842.664185,199.315765 843.003784,190.416260 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M669.500610,262.840088 
	C669.358093,259.482635 669.675720,256.201813 669.986206,252.482300 
	C686.026367,252.043640 702.073669,252.043640 719.019836,252.043640 
	C719.019836,254.636902 719.019836,257.870697 718.992798,261.568665 
	C702.630737,262.327484 686.295776,262.622101 669.500610,262.840088 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M1071.480225,465.884888 
	C1071.005249,462.558197 1070.993774,459.298920 1070.958008,455.599670 
	C1081.013306,454.965271 1081.077637,455.627136 1080.949219,444.268585 
	C1080.593750,412.841888 1080.310791,381.414307 1079.906006,349.988312 
	C1079.779907,340.197083 1079.068604,330.407776 1079.131470,320.620880 
	C1079.252319,301.842072 1079.699707,283.064850 1080.099976,264.288849 
	C1080.173828,260.820892 1080.824463,257.366058 1080.916748,253.897385 
	C1081.010986,250.359131 1083.484741,251.054840 1085.520020,251.050049 
	C1100.001343,251.015945 1114.482544,251.033112 1128.963867,251.030975 
	C1137.452881,251.029709 1145.942383,251.080811 1154.430786,251.003265 
	C1163.825073,250.917465 1167.162476,247.615784 1167.002808,238.403473 
	C1166.757935,224.287003 1166.348389,210.173462 1166.058716,196.057632 
	C1166.021606,194.246429 1166.283081,192.429108 1166.684082,190.308685 
	C1171.941650,189.688675 1176.921509,189.374588 1182.290771,189.059296 
	C1182.452148,204.007004 1182.144531,218.955368 1182.046509,233.905121 
	C1182.018433,238.200424 1182.449463,242.515793 1182.923218,246.793930 
	C1184.027222,256.763336 1179.540283,261.960297 1169.390137,261.985931 
	C1147.069580,262.042358 1124.748657,262.008453 1102.427979,262.011353 
	C1100.607300,262.011597 1098.786621,262.011383 1096.777222,262.011383 
	C1096.487061,263.196564 1096.070190,264.117615 1096.062744,265.041992 
	C1095.708374,308.818237 1095.240234,352.594421 1095.131348,396.371460 
	C1095.078857,417.493530 1095.611572,438.619598 1096.108643,459.738464 
	C1096.219238,464.433472 1094.894165,466.411346 1089.927368,466.092499 
	C1083.954468,465.709106 1077.940063,465.970245 1071.480225,465.884888 
z"
      />
      <path
        fill="#79C0FF"
        opacity="1.000000"
        stroke="none"
        d="
M993.483948,262.867615 
	C993.341980,259.499451 993.663086,256.200409 993.976929,252.483047 
	C1010.025635,252.064758 1026.081665,252.064758 1042.979858,252.064758 
	C1042.979858,254.666351 1042.979858,257.907501 1042.942383,261.616760 
	C1026.585693,262.368866 1010.266296,262.652771 993.483948,262.867615 
z"
      />
      <path
        fill="#7BC1FF"
        opacity="1.000000"
        stroke="none"
        d="
M618.414612,468.818848 
	C618.358398,429.151306 618.630493,389.552643 619.037598,349.955353 
	C619.223816,331.840637 619.549866,313.725220 620.042297,295.616364 
	C620.556458,276.707367 621.355347,257.806183 622.013367,238.901016 
	C622.359680,228.949661 622.813049,218.998810 622.933472,209.044281 
	C622.987305,204.593399 622.001160,200.126907 622.094727,195.680283 
	C622.223816,189.542969 625.005676,187.112717 631.444214,187.005798 
	C630.938843,207.228882 630.032227,227.448441 629.136169,247.668472 
	C629.069885,249.165176 629.084106,250.665375 629.053650,252.163788 
	C628.682068,270.455078 628.387207,288.748352 627.915955,307.037109 
	C627.380981,327.797058 626.352234,348.549896 626.165588,369.311493 
	C626.005676,387.099640 626.577515,404.900452 627.121460,422.686615 
	C627.460999,433.789581 628.447876,444.871216 628.962097,455.970856 
	C629.419983,465.855499 628.497986,466.889038 618.414612,468.818848 
z"
      />
      <path
        fill="#34A0FF"
        opacity="1.000000"
        stroke="none"
        d="
M191.190704,327.081543 
	C173.172272,317.133087 155.563858,307.248016 137.873596,297.511688 
	C133.994217,295.376556 131.009445,292.798187 131.260681,288.067932 
	C131.495560,283.645569 134.677277,281.571838 138.386810,279.953461 
	C156.608765,272.003693 174.822495,264.033539 192.956146,255.885880 
	C194.353485,255.258041 195.751053,253.333649 196.065857,251.790970 
	C199.874237,233.128235 203.380539,214.403748 207.208679,195.745239 
	C208.116104,191.322372 207.843094,185.455521 214.408279,184.934875 
	C217.054245,184.725067 220.789291,184.993118 222.353851,186.637360 
	C230.216431,194.900314 237.481323,203.729004 245.041824,212.283264 
	C251.518707,219.611420 258.133026,226.817917 264.644379,234.115875 
	C268.333740,238.250977 272.644562,234.941925 276.321411,234.617111 
	C292.113556,233.222046 307.825470,230.916626 323.564728,228.927902 
	C325.528290,228.679810 327.662781,227.696228 329.398773,228.185776 
	C332.650085,229.102661 336.204590,230.243408 338.643555,232.406601 
	C341.562378,234.995331 339.919800,238.444687 338.145325,241.470108 
	C327.899384,258.938751 317.664368,276.414459 307.589203,293.981567 
	C306.794617,295.367004 306.575684,297.769287 307.217896,299.188049 
	C315.523010,317.534698 323.971252,335.817627 332.541443,354.042297 
	C334.329010,357.843658 334.916809,361.269226 331.888275,364.578400 
	C329.072205,367.655426 325.973450,368.167755 321.686127,367.148956 
	C304.698273,363.111938 287.347198,360.436279 270.599274,355.625153 
	C261.954468,353.141785 257.223541,355.931061 251.626999,361.427734 
	C239.388168,373.448029 226.572144,384.879608 214.038986,396.602020 
	C211.318085,399.146912 208.405640,400.836975 204.601501,399.577362 
	C200.628098,398.261719 199.009583,395.211029 198.629944,391.207794 
	C197.143875,375.536713 195.663010,359.864471 194.025497,344.208832 
	C193.430008,338.515411 192.402008,332.867188 191.190704,327.081543 
M169.238068,284.738190 
	C165.862045,286.127106 162.486023,287.516052 158.681671,289.081177 
	C160.450821,290.337830 161.465317,291.215820 162.614227,291.850464 
	C175.603577,299.025299 188.582245,306.220734 201.635971,313.277100 
	C205.838043,315.548615 208.681641,318.382172 209.035873,323.513641 
	C209.889084,335.873596 210.939880,348.222015 212.094849,360.557861 
	C212.455078,364.405334 213.403748,368.197754 214.241623,372.906555 
	C216.196304,371.272400 217.496475,370.334564 218.619614,369.218597 
	C222.031647,365.828400 225.234360,362.215637 228.775482,358.969788 
	C235.702759,352.620148 243.324097,346.936401 249.635712,340.041138 
	C253.343307,335.990692 257.078644,335.410950 261.495941,336.308197 
	C270.661804,338.170074 279.734894,340.484680 288.860291,342.550598 
	C295.795319,344.120605 302.752228,345.593994 310.581848,347.303009 
	C307.012970,339.617218 303.933716,332.945007 300.819489,326.289154 
	C296.659271,317.397766 292.076080,308.672333 288.540161,299.540436 
	C287.493774,296.837921 288.508545,292.526154 290.046021,289.770355 
	C297.998840,275.515503 306.427551,261.526154 314.866577,247.143723 
	C313.505127,247.046219 312.677094,246.853165 311.884491,246.949982 
	C296.993713,248.768829 282.090179,250.498734 267.229767,252.541672 
	C262.649933,253.171295 258.857056,252.877365 255.589493,249.119446 
	C247.670883,240.012482 239.715912,230.925491 231.440521,222.146286 
	C228.268234,218.780853 224.184464,216.274582 220.994293,213.754578 
	C218.466904,227.081848 215.721634,241.777832 212.875183,256.454193 
	C211.812820,261.931915 211.036850,267.307831 204.489594,269.818787 
	C192.819611,274.294434 181.414795,279.461456 169.238068,284.738190 
z"
      />
      <path
        fill="#33A0FF"
        opacity="1.000000"
        stroke="none"
        d="
M310.121277,440.798157 
	C313.357239,451.684875 316.495148,462.182190 319.835419,473.356567 
	C298.372955,479.775543 277.413574,486.044037 255.802063,492.507599 
	C250.707458,475.489105 245.726974,458.851715 240.596893,441.714661 
	C262.038025,435.307373 283.030212,429.034210 304.610229,422.585388 
	C306.478821,428.738129 308.251007,434.573425 310.121277,440.798157 
z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M169.568588,284.538757 
	C181.414795,279.461456 192.819611,274.294434 204.489594,269.818787 
	C211.036850,267.307831 211.812820,261.931915 212.875183,256.454193 
	C215.721634,241.777832 218.466904,227.081848 220.994293,213.754578 
	C224.184464,216.274582 228.268234,218.780853 231.440521,222.146286 
	C239.715912,230.925491 247.670883,240.012482 255.589493,249.119446 
	C258.857056,252.877365 262.649933,253.171295 267.229767,252.541672 
	C282.090179,250.498734 296.993713,248.768829 311.884491,246.949982 
	C312.677094,246.853165 313.505127,247.046219 314.866577,247.143723 
	C306.427551,261.526154 297.998840,275.515503 290.046021,289.770355 
	C288.508545,292.526154 287.493774,296.837921 288.540161,299.540436 
	C292.076080,308.672333 296.659271,317.397766 300.819489,326.289154 
	C303.933716,332.945007 307.012970,339.617218 310.581848,347.303009 
	C302.752228,345.593994 295.795319,344.120605 288.860291,342.550598 
	C279.734894,340.484680 270.661804,338.170074 261.495941,336.308197 
	C257.078644,335.410950 253.343307,335.990692 249.635712,340.041138 
	C243.324097,346.936401 235.702759,352.620148 228.775482,358.969788 
	C225.234360,362.215637 222.031647,365.828400 218.619614,369.218597 
	C217.496475,370.334564 216.196304,371.272400 214.241623,372.906555 
	C213.403748,368.197754 212.455078,364.405334 212.094849,360.557861 
	C210.939880,348.222015 209.889084,335.873596 209.035873,323.513641 
	C208.681641,318.382172 205.838043,315.548615 201.635971,313.277100 
	C188.582245,306.220734 175.603577,299.025299 162.614227,291.850464 
	C161.465317,291.215820 160.450821,290.337830 158.681671,289.081177 
	C162.486023,287.516052 165.862045,286.127106 169.568588,284.538757 
z"
      />
    </svg>
  );
}
