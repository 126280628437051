export function AppFooter() {
  return (
    <div className="home-footer main-layout">
      <span>
        ©2024 ItToDoo.com Created by{" "}
        <a href="https://github.com/Devam-Savjani">Devam Savjani</a>
      </span>
    </div>
  );
}
